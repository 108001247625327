<template>
  <el-main>
    <div style="margin-bottom: 15px">
      <el-button type="primary" class="el-icon-plus" size="small" @click="add">添加海报</el-button>
    </div>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="海报名称：">
        <el-input size="small" v-model="searchForm.name" placeholder="请输入海报名称"></el-input>
      </el-form-item>
      <el-form-item label="添加日期：">
        <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="15px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column width="120" align="center">
        <template #header>
          <div class="edit">
            <span>排序</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template  v-slot="{ row }">
          <el-input type="number" :min="0" size="small" v-model="row.sort" @change="editSort(row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="海报名称" align="center"></el-table-column>
      <el-table-column prop="image" label="素材图" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 140px; height: 70px" fit="cover" :src="row.image" :preview-src-list="[row.image]"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="添加时间" align="center" width="200">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="edit(row)" type="text">编辑</el-button>
          <el-button @click="del(row.id)" type="text">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog :title="addForm.id ? '编辑' : '添加'" :visible.sync="showAdd" width="650px">
      <el-form ref="form" :model="addForm" :rules="rules" label-width="100px">
        <el-form-item label="排序：">
          <el-input v-model="addForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="海报名称：" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="素材图：" prop="image">
          <ReadyUploadSource @getSource="val => (addForm.image = val.path)" :path="addForm.image" @removeThis="() => (addForm.image = '')"></ReadyUploadSource>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="confirmAdd">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Paging,
    ReadyUploadSource,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        name: '',
        start_time: '',
        end_time: '',
      },
      addForm: {
        id: '',
        sort: 99,
        name: '',
        image: '',
      },
      rules: {
        name: { required: true, message: '请填写海报名称', trigger: 'blur' },
        image: { required: true, message: '请添加素材图', trigger: 'blur' },
      },
      list: [],
      total: 0,
      showAdd: !1,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        name: '',
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    edit(row) {
      this.addForm = {
        id: row.id,
        sort: row.sort,
        name: row.name,
        image: row.image,
      };
      this.showAdd = !0;
    },
    add() {
      this.addForm = {
        sort: 99,
        name: '',
        image: '',
      };
      this.showAdd = !0;
    },
    del(id) {
      this.$confirm('请确认是否删除海报？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios.post(this.$api.serviceProvider.delPoster, { id }).then(res => {
          if (res.code == 0) {
            this.getList();
            this.$message.success('删除成功');
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    editSort(row){
         this.$axios.post(this.$api.serviceProvider.editPoster,{
             id:row.id,
             sort:row.sort,
             name:row.name,
             image:row.image
         }).then(res => {
            if (res.code == 0) {
                console.log(res)
            } else {
              this.$message.error(res.msg);
            }
          });
    },
    confirmAdd() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios.post(this.addForm.id ? this.$api.serviceProvider.editPoster : this.$api.serviceProvider.addPoster, this.addForm).then(res => {
            if (res.code == 0) {
              this.getList();
              this.showAdd = !1;
              this.$message.success(this.addForm.id ? '编辑成功' : '添加成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.serviceProvider.posterList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-table {
    /deep/ .el-input__inner {
      border-color: #fff;
    }
    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }
    .edit {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-right: 5px;
      }
      i {
        font-size: 17px;
      }
    }
  }
}
</style>